<template>
  <div id="popupWrapper">
    <ejs-dialog
      allowDragging="true"
      header="조건 검색"
      isModal="true"
      ref="dialog"
      showCloseIcon="true"
      width="450"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.salesDivisions }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        cssClass="lookupDetail-condition-multiselect"
                        v-model="privateFilterOptions.salesDivisions"
                        v-bind="salesDivisionMultiSelectProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.businessCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        cssClass="lookupDetail-condition-dropdown"
                        v-model="privateFilterOptions.businessCode"
                        v-bind="businessDropdownListProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.proofTypes }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        cssClass="lookupDetail-condition-multiselect"
                        v-model="privateFilterOptions.proofTypes"
                        v-bind="proofTypeMultiSelectProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.approvalDivisions }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li
                          v-for="approvalDivision in approvalDivisions"
                          v-bind:key="approvalDivision.value"
                        >
                          <label>
                            <input
                              type="checkbox"
                              :id="`approval-division-${approvalDivision.value}`"
                              :value="approvalDivision.value"
                              v-model="privateFilterOptions.approvalDivisions"
                              :disabled="privateFilterOptions.cancelViewFlag"
                            />
                            <i></i>
                            <div
                              class="label"
                              :for="`approval-division-${approvalDivision.value}`"
                            >
                              {{ approvalDivision.text }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.trnscDiv }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          cssClass="lookupDetail-condition-dropdown"
                          v-model="privateFilterOptions.trnscDiv"
                          v-bind="trnscDivDropdownListProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.storeCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        cssClass="lookupDetail-condition-dropdown"
                        v-model="privateFilterOptions.storeCode"
                        v-bind="storeDropdownListProps"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.approvalNumber }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="privateFilterOptions.approvalNumber"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.proofNumber }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text v-model="privateFilterOptions.proofNumber" />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title" />
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="privateFilterOptions.cancelViewFlag"
                              @change="onCancelViewFlagCheck"
                            />
                            <i></i>
                            <div class="label">
                              {{ labels.cancelViewFlag }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  @click.native="onSubmitClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onResetClick"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { sortBy as _sortBy } from "lodash";
import GolfERPService from "@/service/GolfERPService";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton.vue";

const DEFAULT_FILTER_OPTIONS = {
  salesDivisions: [],
  proofTypes: [],
  approvalDivisions: [],
  storeCode: "",
  businessCode: "",
  approvalNumber: "",
  proofNumber: "",
  cancelViewFlag: false,
};

export default {
  name: "CashReceiptsReportsFilterOptionsPopup",
  components: {
    InputText,
    ErpButton,
  },
  props: {
    filterOptions: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(DEFAULT_FILTER_OPTIONS)),
    },
    labels: {
      type: Object,
      default: null,
    },
  },
  async mounted() {
    try {
      await this.init();
      this.show();
    } catch (e) {
      this.hide();
      throw e;
    }
  },
  watch: {
    isLoading(isLoading) {
      if (isLoading) {
        this.$EventBus.$emit("loaderOn");
      } else {
        this.$EventBus.$emit("loaderOff");
      }
    },
  },
  data() {
    return {
      isLoading: false,

      stores: [],
      workPlaces: [],

      privateFilterOptions: this.filterOptions,
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
    salesDivisionMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("SALES_DIV"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    proofTypeMultiSelectProps() {
      const dataSource = _sortBy(
        commonCodesGetCommonCode("PROOF_KIND"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
    approvalDivisions() {
      return _sortBy(
        commonCodesGetCommonCode("APPROVAL_DIV").filter(data => data.comCode !== "NA"),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
    },
    trnscDivDropdownListProps() {
      const dataSource = _sortBy(
          commonCodesGetCommonCode("TRNSC_DIV"),
          "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      dataSource.unshift({ value: "", text: "전체" });
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
    storeDropdownListProps() {
      const storeCodes = this.stores.map(({ code }) => code);
      const dataSource = _sortBy(
        commonCodesGetCommonCode("STORE_CODE").filter(({ comCode }) =>
          storeCodes.includes(comCode)
        ),
        "sortNo"
      ).map(({ comCode: value, comName: text }) => ({ value, text }));
      dataSource.unshift({ value: "", text: "전체" });

      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
    businessDropdownListProps() {
      const dataSource = _sortBy(
        this.workPlaces,
        "order"
      ).map(({ id, name: text }) => ({ value: `${id}`, text }));
      dataSource.unshift({ value: "", text: "전체" });
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onSubmitClick() {
      this.$emit("submit", this.privateFilterOptions);
    },
    onResetClick() {
      this.privateFilterOptions = JSON.parse(
        JSON.stringify(DEFAULT_FILTER_OPTIONS)
      );
    },
    onCloseClick() {
      this.hide();
    },

    async init() {
      this.isLoading = true;

      try {
        const [stores, workPlaces] = await Promise.all([
          GolfERPService.fetchStores(true),
          GolfERPService.fetchWorkPlaces(),
        ]);

        this.stores = stores;
        this.workPlaces = workPlaces;
      } finally {
        this.isLoading = false;
      }
    },
    onCancelViewFlagCheck() {
      if (this.privateFilterOptions.cancelViewFlag) {
        this.privateFilterOptions.approvalDivisions = [];
      }
    },
  },
};
</script>

<style scoped></style>
