<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field businessDate">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  v-model="dateDivision"
                  v-bind="dateDivisionDropdownListProps"
                />
              </li>
              <li class="item dateRange">
                <input-date-range type="lookup-condition" v-model="dateRange" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.payerName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="payerName"
                  @keydown.native="preventEnterEvent"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onFetchCashReceiptsReportsClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="onFetchDetailOptionsPopupOpenClick"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">현금 영수증 발행 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      buttonDiv="PRINT"
                      @click.native="print"
                      :isShortcutButton="true"
                      :ignore="isPopupOpened"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="cashReceiptsReportsGrid"
              v-bind="cashReceiptsReportsGridProps"
              :dataSource="cashReceiptsReportsGridDataSource"
              @headerCellInfo="onCashReceiptsReportsGridHeaderCellInfo"
              @queryCellInfo="onCashReceiptsReportsGridQueryCellInfo"
              @actionComplete="onCashReceiptsReportsGridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <cash-receipts-reports-filter-options-popup
      ref="cashReceiptsReportsFilterOptionsPopup"
      v-if="visibleCashReceiptsReportsFilterOptionsPopup"
      :filter-options="filterOptions"
      :labels="labels"
      @submit="onCashReceiptsReportsFilterOptionsPopupSubmit"
      @close="onCashReceiptsReportsFilterOptionsPopupClose"
    />
    <pay-cancel-popup
      v-if="visiblePayCancelPopup"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClose"
    />
    <re-issue-receipt-popup
      v-if="visibleReIssueReceiptPopup"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.businessDate .content .item.select {width: 120px;}
body .content-body .article-section .section-body {overflow: hidden;border: none;}
</style>

<script>
import { orderBy as _orderBy } from "lodash";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputText from '@/components/common/text/InputText';
import InputDateRange from "@/components/common/datetime/InputDateRange";
import GolfERPService from "@/service/GolfERPService";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getDayOfWeekCaption,
  getDayColorValue,
} from "@/utils/date";
import CashReceiptsReportsFilterOptionsPopup from "@/views/credit-management/popup/CashReceiptsReportsFilterOptionsPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  commonCodesGetColorValue,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import {
  Aggregate,
  Edit,
  ForeignKey,
  Group,
  Resize,
  Filter,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import { numberWithCommas } from "@/utils/number";

import EjsGridDropdownButtonColumn from "../../components/common/EjsGridDropdownButtonColumn";
import PayCancelPopup from "@/components/popups/PayCancelPopup";
import ReIssueReceiptPopup from "@/components/popups/ReIssueReceiptPopup";
import { mapGetters } from "vuex";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ReportView from "@/components/common/report/ReportView";


import {formPreventEnterEvent} from '@/utils/formUtil';

export default {
  name: "CashReceiptsReports",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ReIssueReceiptPopup,
    PayCancelPopup,
    EjsGridWrapper,
    CashReceiptsReportsFilterOptionsPopup,
    InputText,
    InputDateRange,
    ReportView,
    ErpButton
  },
  filters: {
    numberWithCommas,
  },
  async created() {
    await this.initialize();
  },
  data() {
    return {
      count: 0,

      isLoading: false,
      cashReceiptsReports: [],

      now: null,
      dateDivision: "business",
      dateRange: {
        from: null,
        to: null,
      },
      payerName: "",
      filterOptions: {
        salesDivisions: [],
        proofTypes: [],
        approvalDivisions: ["OK", "T_OK"],
        trnscDiv: "",
        storeCode: "",
        businessCode: "",
        approvalNumber: "",
        cardNumber: "",
      },

      labels: {
        payerName: "지불자명",
        salesDivisions: "매출구분",
        businessCode: "영업장",
        proofTypes: "증빙종류",
        approvalDivisions: "승인구분",
        trnscDiv: "거래구분",
        storeCode: "매장",
        approvalNumber: "승인번호",
        proofNumber: "증빙번호",
        cancelViewFlag: "원거래 취소자료 보기",
      },

      visibleCashReceiptsReportsFilterOptionsPopup: false,
      visiblePrintCreditCardTotalRecordsPopup: false,
      visiblePrintPaper: false,

      visiblePayCancelPopup: false,
      visibleReIssueReceiptPopup: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.visibleCashReceiptsReportsFilterOptionsPopup ||
        this.visiblePayCancelPopup ||
        this.visibleReIssueReceiptPopup
      );
    },
    datetimeString() {
      return moment(this.nonNullDatetime).format(`YYYY-MM-DD HH:mm:ss`);
    },
    dateDivisionDropdownListProps() {
      return {
        allowFiltering: false,
        dataSource: [
          {
            value: "business",
            text: "영업일자",
          },
          {
            value: "approval",
            text: "승인일자",
          },
        ],
        fields: {
          text: "text",
          value: "value",
        },
      };
    },
    cashReceiptsReportsGridDataSource() {
      return _orderBy(
        this.cashReceiptsReports.map(
          (
            {
              id,
              deleted,
              salesDivision,
              businessName,
              salesDate,
              businessDivisionCode,
              proofType,
              number,
              approvalDivision,
              approvalNumber,
              approvalAmount,
              taxAmount,
              vatAmount,
              noTaxAmount,
              storeCode,
              payerName,
              payerInfo,
              terminalId,
              trnscDiv,
              insertUser,
              approvalDateTime,
              visitId,
              stayId
            },
            index
          ) => ({
            _rid: index + 1,
            id,
            deleted,
            salesDivision,
            businessName,
            salesDate,
            salesDateGroup: salesDate,
            salesDateOfWeek: (moment(salesDate).day() + 1).toString(),
            salesDateOfWeekCaption: getDayOfWeekCaption(salesDate),
            businessDivisionCode,
            proofType,
            number,
            approvalDivision,
            approvalNumber,
            approvalAmount,
            taxAmount,
            vatAmount,
            noTaxAmount,
            storeCode,
            payerName,
            payerInfo,
            terminalId,
            trnscDiv,
            insertUserName: insertUser ? insertUser.name : "",
            approvalDateTime,
            visitId,
            stayId
          })
        ),
        ["salesDate", "approvalDateTime"],
        ["asc", "desc"]
      );
    },
    cashReceiptsReportsGridColumns() {
      return [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "id",
          visible: false,
        },
        {
          field: "deleted",
          visible: false,
        },
        {
          field: "salesDateGroup",
          visible: false,
        },
        {
          allowEditing: false,
          field: "businessName",
          headerText: "영업장",
          minWidth: 16,
          textAlign: "Left",
          width: 90,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "SALES_DIV",
          editType: "dropdownedit",
          field: "salesDivision",
          isCommonCodeField: true,
          headerText: "매출구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "salesDate",
          headerText: "영업일자",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "BSN_CODE",
          editType: "dropdownedit",
          field: "businessDivisionCode",
          isCommonCodeField: true,
          headerText: "영업구분",
          minWidth: 16,
          textAlign: "Center",
          width: 90,
          type: "string",
        },
        {
          allowEditing: false,
          field: "salesDateOfWeekCaption",
          headerText: "요일",
          minWidth: 16,
          textAlign: "Center",
          width: 70,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "PROOF_KIND",
          editType: "dropdownedit",
          field: "proofType",
          isCommonCodeField: true,
          headerText: "증빙구분",
          minWidth: 16,
          textAlign: "Center",
          width: 90,
          type: "string",
        },
        {
          allowEditing: false,
          field: "number",
          headerText: "증빙번호",
          minWidth: 16,
          textAlign: "Left",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "APPROVAL_DIV",
          editType: "dropdownedit",
          field: "approvalDivision",
          isCommonCodeField: true,
          headerText: "승인구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "approvalNumber",
          headerText: "승인번호",
          minWidth: 16,
          textAlign: "Left",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          field: "contextMenu",
          headerText: "",
          minWidth: 16,
          textAlign: "Left",
          width: 24,
          template: () => {
            const onReportRecordDropdownButtonSelect = this
              .onReportRecordDropdownButtonSelect;
            return {
              template: {
                components: { EjsGridDropdownButtonColumn },
                template:
                  "<ejs-grid-dropdown-button-column :dropdownButtonItems='dropdownButtonItems' @select='onDropdownButtonSelect'/>",
                data() {
                  return {
                    data: {},
                  };
                },
                computed: {
                  dropdownButtonItems() {
                    if (
                      (this.data.approvalDivision === "OK" ||
                        this.data.approvalDivision === "T_OK") &&
                      !this.data.deleted
                    ) {
                      return [
                        {
                          text: "정산취소",
                          value: "cancel",
                        },
                        {
                          text: "영수증 재발행",
                          value: "republic",
                        },
                      ];
                    } else {
                      return [
                        {
                          text: "영수증 재발행",
                          value: "republic",
                        },
                      ];
                    }
                  },
                },
                methods: {
                  onDropdownButtonSelect(args) {
                    onReportRecordDropdownButtonSelect({
                      args,
                      data: this.data,
                    });
                  },
                },
              },
            };
          },
        },
        {
          allowEditing: false,
          field: "approvalAmount",
          headerText: "승인금액",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 90,
        },
        {
          allowEditing: false,
          field: "taxAmount",
          headerText: "과세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 90,
        },
        {
          allowEditing: false,
          field: "vatAmount",
          headerText: "부가세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 90,
        },
        {
          allowEditing: false,
          field: "noTaxAmount",
          headerText: "면세",
          minWidth: 16,
          textAlign: "Right",
          type: "number",
          format: "N",
          width: 90,
        },
        {
          allowEditing: false,
          groupCode: "STORE_CODE",
          editType: "dropdownedit",
          field: "storeCode",
          isCommonCodeField: true,
          headerText: "매장",
          minWidth: 16,
          textAlign: "Center",
          width: 70,
          type: "string",
        },
        {
          allowEditing: false,
          headerText: "지불자",
          field: "payerName",
          type: "string",
          width: "90",
          textAlign: "left",
        },
        {
          allowEditing: false,
          headerText: "지불자 정보",
          field: "payerInfo",
          type: "string",
          width: "120",
          textAlign: "left",
        },
        {
          allowEditing: false,
          field: "terminalId",
          headerText: "단말ID",
          minWidth: 16,
          textAlign: "Center",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          groupCode: "TRNSC_DIV",
          editType: "dropdownedit",
          field: "trnscDiv",
          isCommonCodeField: true,
          headerText: "거래구분",
          minWidth: 16,
          textAlign: "Center",
          width: 100,
          type: "string",
        },
        {
          allowEditing: false,
          field: "insertUserName",
          headerText: "승인처리자",
          minWidth: 16,
          textAlign: "Center",
          width: 120,
          type: "string",
        },
        {
          allowEditing: false,
          field: "approvalDateTime",
          headerText: "승인일시",
          minWidth: 16,
          textAlign: "Left",
          width: 120,
          type: "string",
        },
        {
          field: "visitId",
          visible: false
        },
        {
          field: "stayId",
          visible: false,
        },
      ];
    },
    cashReceiptsReportsGridProps() {
      return {
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        allowPaging: true,
        columns: this.cashReceiptsReportsGridColumns,
        // groupSettings: {
        //   columns: ["salesDate", "salesDivision"],
        //   showDropArea: false
        // },
        pageSettings: { pageSize: 50 },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        aggregates: [
          {
            columns: [
              {
                field: "businessName",
                aggregationType: "GroupCaption",
                customAggregate: "소계",
              },
              {
                field: "approvalAmount",
                aggregationType: "GroupSum",
              },
              {
                field: "taxAmount",
                aggregationType: "GroupSum",
              },
              {
                field: "vatAmount",
                aggregationType: "GroupSum",
              },
              {
                field: "noTaxAmount",
                aggregationType: "GroupSum",
              },
            ],
          },
          {
            columns: [
              {
                field: "businessName",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "approvalAmount",
                aggregationType: "TotalSum",
              },
              {
                field: "taxAmount",
                aggregationType: "TotalSum",
              },
              {
                field: "vatAmount",
                aggregationType: "TotalSum",
              },
              {
                field: "noTaxAmount",
                aggregationType: "TotalSum",
              },
            ],
          },
        ],
        groupSettings: { showDropArea: false, columns: ["salesDateGroup"] },
        selectionSettings: { type: "Single", enableToggle: false },
      };
    },
    layerButtonCaption() {
      return this.visibleBottomLayer ? "닫기" : "열기";
    },
  },
  asyncComputed: {},
  methods: {
    numberWithCommas,
    preventEnterEvent: formPreventEnterEvent,
    async onFetchCashReceiptsReportsClick() {
      await this.fetchCashReceiptsReports();
    },
    onFetchDetailOptionsPopupOpenClick() {
      this.visibleCashReceiptsReportsFilterOptionsPopup = true;
    },
    onCashReceiptsReportsGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === "contextMenu") {
        node.classList.add(this.$t("className.grid.contextMenuArea"));
      }
    },
    onCashReceiptsReportsGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field === "salesDateOfWeekCaption") {
        cell.style.color = getDayColorValue(data.dwCode, data.businessDivisionCode);
      }
      if (field === "businessDivisionCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          data.businessDivisionCode
        );
      }
      if (field === "approvalDivision") {
        if (
          (data.approvalDivision === "OK" || data.approvalDivision === "T_OK") &&
          data.deleted
        ) {
          cell.style.color = "red";
        } else {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            data.approvalDivision
          );
        }
      }
      if (
        (field === "approvalAmount" || field === "noTaxAmount") &&
        0 < Math.abs(data.noTaxAmount)
      ) {
        cell.style.color = commonCodesGetColorValue("NOTAX_CODE", "NOTAX");
      }
      if ([
        "approvalAmount",
        "taxAmount",
        "vatAmount",
        "noTaxAmount",
      ].includes(field) && args.data[field] === 0) {
        cell.innerText = "-";
      }
    },
    onCashReceiptsReportsGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.cashReceiptsReportsGrid?.getGridBatchCount() || 0
      );
    },
    async payCancelPopupClose(args) {
      this.visiblePayCancelPopup = false;

      if (args !== undefined && args.isReload) {
        await this.onFetchCashReceiptsReportsClick();
      }
    },
    reIssueReceiptPopupClose() {
      this.visibleReIssueReceiptPopup = false;
    },
    onLayerButtonClick() {
      this.visibleBottomLayer = !this.visibleBottomLayer;
    },
    onPrintTotalChartClick() {
      this.visiblePrintCreditCardTotalRecordsPopup = true;
    },
    async onCashReceiptsReportsFilterOptionsPopupSubmit(payload) {
      this.filterOptions = payload;
      await this.fetchCashReceiptsReports();
    },
    onCashReceiptsReportsFilterOptionsPopupClose() {
      this.visibleCashReceiptsReportsFilterOptionsPopup = false;
    },
    onPrintCreditCardTotalRecordsPopupClose() {
      this.visiblePrintCreditCardTotalRecordsPopup = false;
    },
    onPrintPaperClose() {
      this.visiblePrintPaper = false;
    },
    onReportRecordDropdownButtonSelect(event) {
      const {
        args: {
          item: { value: menu },
        },
        data: data,
      } = event;

      if (
        (data.approvalDivision.toUpperCase() === "OK" ||
          data.approvalDivision.toUpperCase() === "T_OK") &&
        data.deleted
      ) {
        this.errorToast("이미 승인 취소된 자료 입니다");
        return;
      }

      if (menu === "cancel") {
        this.visiblePayCancelPopup = true;
        this.$nextTick(() => {
          this.$refs.payCancelPopup.showPayCancelPopup({
            salesDiv: data.salesDivision,
            payDate: data.salesDate,
            cashId: data.id,
            payDiv: "CASH",
            bsnDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
            payAmt: data.approvalAmount,
            visitName: "",
            teamGrpFlag: null,
            grpNo: null,
            storeCode: data.storeCode,
            isOnlyCardCancel: true,
            isPayCash: true,
            visitId: data.visitId,
            stayId: data.stayId,
          });
        });
      } else if (menu === "republic") {
        this.visibleReIssueReceiptPopup = true;
        this.$nextTick(() => {
          this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
            payId: data.id,
            isPayCash: true,
          });
        });
      }
    },

    async initialize() {
      this.isLoading = true;

      try {
        const nowMoment = await GolfERPService.fetchNow();
        this.now = nowMoment.toDate();

        const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
        const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
        this.dateRange = {
          from,
          to,
        };

        await this.fetchCashReceiptsReports();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCashReceiptsReports() {
      if (!this.dateRange.from) {
        const columnName = this.dateDivisionDropdownListProps.dataSource.find(
          (item) => item.value === this.dateDivision
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [columnName.text])
        );
      }
      if (!this.dateRange.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      this.isLoading = true;

      let filterOptionArgs = JSON.parse(JSON.stringify(this.filterOptions));

      if (filterOptionArgs.approvalDivisions.length === 4) {
        filterOptionArgs.approvalDivisions = [];
      }

      try {
        this.cashReceiptsReports = await GolfERPService.fetchCashReceiptsReports(
          this.payerName,
          this.dateDivision,
          this.dateRange.from,
          this.dateRange.to,
          filterOptionArgs
        );
      } finally {
        this.isLoading = false;
      }
    },
    async print() {
      const options = [
        {
          field: "salesDivision",
          comCode: "SALES_DIV",
          format: "comCode",
        },
        {
          field: "businessDivisionCode",
          comCode: "BSN_CODE",
          format: "comCode",
        },
        {
          field: "salesDateOfWeek",
          targetField: "salesDateOfWeekCaption",
          comCode: "DW_CODE",
          format: "comCode",
        },
        {
          field: "proofType",
          comCode: "PROOF_KIND",
          format: "comCode",
        },
        {
          field: "approvalDivision",
          comCode: "APPROVAL_DIV",
          format: "comCode",
        },
        {
          field: "trnscDiv",
          comCode: "TRNSC_DIV",
          format: "comCode",
        },
        {
          field: "storeCode",
          comCode: "STORE_CODE",
          format: "comCode",
        },
      ];

      let result = this.$refs.cashReceiptsReportsGrid.getGridBatchData(options);
      result = result.map((item) => {
        item.tempCount = 1;
        return item;
      });

      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const workPlaces = await GolfERPService.fetchWorkPlaces();

      let salesDivisionsCaptionList = [];
      let proofTypesCaptionList = [];
      let approvalDivisionsCaptionList = [];

      if (this.filterOptions.salesDivisions.length > 0) {
        this.filterOptions.salesDivisions.map((item) =>
          salesDivisionsCaptionList.push(
            commonCodesGetComName("SALES_DIV", item)
          )
        );
      }
      if (this.filterOptions.proofTypes.length > 0) {
        this.filterOptions.proofTypes.map((item) =>
          proofTypesCaptionList.push(
            commonCodesGetComName("PROOF_KIND", item)
          )
        );
      }
      if (this.filterOptions.approvalDivisions.length > 0) {
        this.filterOptions.approvalDivisions.map((item) =>
          approvalDivisionsCaptionList.push(
            commonCodesGetComName("APPROVAL_DIV", item)
          )
        );
      }
      const searchOptionsList = [
        {
          key: this.labels.payerName,
          value: this.payerName !== ''
            ? this.payerName
            : null,
        },
        {
          key: this.dateDivisionDropdownListProps.dataSource.find(
            (item) => item.value === this.dateDivision
          ).text,
          value: this.dateRange.from + " ~ " + this.dateRange.to,
        },
        {
          key: this.labels.salesDivisions,
          value: salesDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.proofTypes,
          value: proofTypesCaptionList.join(", "),
        },
        {
          key: this.labels.businessCode,
          value:
            this.filterOptions.businessCode !== ""
              ? workPlaces.find(
                  (item) =>
                    item.id.toString() === this.filterOptions.businessCode
                ).name
              : null,
        },
        {
          key: this.labels.approvalDivisions,
          value: approvalDivisionsCaptionList.join(", "),
        },
        {
          key: this.labels.trnscDiv,
          value: commonCodesGetComName(
              "TRNSC_DIV",
              this.filterOptions.trnscDiv
          ),
        },
        {
          key: this.labels.storeCode,
          value: commonCodesGetComName(
            "STORE_CODE",
            this.filterOptions.storeCode
          ),
        },
        {
          key: this.labels.approvalNumber,
          value: this.filterOptions.approvalNumber,
        },
        {
          key: this.labels.proofNumber,
          value: this.filterOptions.cardNumber,
        },
        {
          key: this.labels.cancelViewFlag,
          value: this.filterOptions.cancelViewFlag ? "예" : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(
        reportData,
        this.$options.name
      );
    },
    onClickExcel() {
      this.$refs.cashReceiptsReportsGrid.excelExport();
    },
  },
};
</script>
